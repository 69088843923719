//import { wrapper } from "@/store/store";
import React, { useCallback, useEffect, useState, useRef, useLayoutEffect } from "react";
import instagramIcon from "../assets/images/instagram.png";
import facebookIcon from "../assets/images/facebook.png";
import pinterestIcon from "../assets/images/pinterest.png";
import tiktokIcon from "../assets/images/tiktok.png";
import youtubeIcon from "../assets/images/youtube.png";
import heartIcon from "../assets/images/heart.svg";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Box, Button, TextField, Grid } from "@mui/material";
import Layer from "@/components/layer";
import suppliersLogos from '../assets/images/suppliers_logos.png';
import suppliersLogosMobile from '../assets/images/suppliers_logos_mobile.png';
import designerImage from '../assets/images/designer_image.png';
import bannerImage from '../assets/images/LP_Image_R1_for_Video_banner.png';
import bannerImageMweb from '../assets/images/home_carousel_mweb.jpg';
import Alert from '@mui/material/Alert';
import Campaign from "@/store/campaign";
import Image from 'next/image';
import RoomSelectorLayerButton from "@/components/popups/RoomSelectoLayerButton";
import { GetStaticProps, GetStaticPropsContext } from "next";
import { getPageInsertsWithCache, getHeaderDataWithCache, getStaticBasics } from "@/utils/getStaticBasics";
import cssStyles from "./landing.module.scss";
import discoverStyle from "../assets/images/discover_your_style.jpg";
import discoverStyleMweb from "../assets/images/discover_your_style_mweb.png";
import viewInRoom from "../assets/images/view_room_designs.jpg";
import viewInRoomMweb from "../assets/images/view_room_designs_mweb.png";
import homeOwnerProblem from "../assets/images/homeowner_problem.png";
import playButton from "../assets/images/play_btn.png";
import useScreenSize from "@/utils/useScreenSize";
import { getRoomTypeList } from "@/store/commonSlice";
import Carousel from "react-multi-carousel";
import { gtmClickTracking } from "@/utils";
import { useSwipeable } from "react-swipeable";

const CustomDot = ({ onClick, ...rest }: any) => {
    const { index, active } = rest;
    return (
      <li>
        <button
          style={{
                width: '12px',
                height: '12px',
                "borderRadius": "50%",
                opacity: 1,
                backgroundColor: active ? '#080808' : '',
                transition: 'background .5s',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'grey',
                padding: 0,
                margin: 0,
                marginRight: '6px',
                outline: 0,
                cursor: "pointer"
          }}
          onClick={() => {
            console.log(`Dot ${index + 1} clicked`);
            onClick();

            if(index + 1 == 1){
                gtmClickTracking({ event: 'Homepage_plays_video' , id:"Homepage_plays_video"});
            }else if(index + 1 == 2){
                gtmClickTracking({event:"view_slide", id:"view_slide", value: "style"})
            }else if(index + 1 == 3){
                gtmClickTracking({event:"view_slide", id:"view_slide", value: "room"})
            }else if(index + 1 == 4){
                gtmClickTracking({event:"view_slide", id:"view_slide", value: "browse"})
            }
          }}
        />
      </li>
    );
  };

const Welcome = (props: any) => {

    const {
        env,
        roomTypes,
    } = typeof props.message == "string" ? JSON.parse(props.message) : props.message;
    const [canAutoPlay, setCanAutoPlay] = useState(true);
    const [showVideo, setShowVideo] = useState(false);


    const searchZip = useCallback(() => {
        // $('#zip-error').show()
    }, []);

    const toogleZipSearch = useCallback(() => {
        const zipSearchModalElem: any = document.getElementById("zip-search");
        zipSearchModalElem.modal('toggle');
        //$('#zip-search').modal('toggle');
        //$('#zip-error').hide();
        //("#zip").val("");
    }, []);

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const [openSearchZipCodeLayer, setOpenSearchZipCodeLayer] = useState(false);
    const handleSearchZipCodeLayerOpen = () => {
        setOpenSearchZipCodeLayer(true);
    };
    const handleSearchZipCodeLayerClose = () => {
        setOpenSearchZipCodeLayer(false);
    };
    const videoRef = useRef<HTMLVideoElement>(null);
    const screenSize = useScreenSize();
    const carouselRef = useRef<any>();

    const responsiveData = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 0 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 767, min: 601 },
            slidesToSlide: 1,
            items: 1
        },
        mobile: {
            breakpoint: { max: 600, min: 320 },
            slidesToSlide: 1,
            items: 1
        }
    };

    const imageLoaded = () => {
        if (videoRef.current) {
            console.log('Loading video');
            
            gtmClickTracking({ event: 'Homepage_plays_video' });
            const source = document.createElement('source');
            source.src = screenSize.width > 768 ? "https://d221a61rb87vel.cloudfront.net/Videos/catalog-assets/LP_Video_V8.mp4" : "https://d221a61rb87vel.cloudfront.net/Videos/catalog-assets/m_LP_Video_V8.mp4";
            source.type = "video/mp4";
            videoRef.current.appendChild(source);
            videoRef.current.load();
            const bannerContainer: HTMLElement | null = document.getElementById('banner-container');
            if (bannerContainer) {
                bannerContainer.classList.add("video-loaded");
            }
        }
    }

    useEffect(() => {
        imageLoaded();
    }, [showVideo]);

    const handlers = useSwipeable({
        onSwipedLeft: () => {console.log('Swiped Left!');setCanAutoPlay(false);},
        onSwipedRight: () => {console.log('Swiped Right!');setCanAutoPlay(false);},
        onSwipedUp: () => {console.log('Swiped Up!');setCanAutoPlay(false);},
        onSwipedDown: () => {console.log('Swiped Down!');setCanAutoPlay(false);},
        trackMouse: true, // Track mouse events as well
      });

    return (
        <>
            <main className={"home-container"}>
                <Campaign />

                <section {...handlers} className={"mobile-slider"} style={{ 'padding': '0px' }} onClick={() => { setCanAutoPlay(false); }}>
                    <Carousel
                        ref={carouselRef}
                        partialVisible={true}
                        ssr={true}
                        showDots={true}
                        autoPlay={canAutoPlay}
                        responsive={responsiveData}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        keyBoardControl={true}
                        autoPlaySpeed={2500}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        shouldResetAutoplay={true}
                        rewind={true}
                        arrows={false}
                        customDot={<CustomDot />}
                    >
                        <section id="hero-slider" className={cssStyles["hero-slider"]}>
                            <div className={cssStyles["container-fluid"]}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        {

                                            showVideo ?
                                                (<div className="banner-section" id="banner-container">
                                                    {/* <div id="banner-image" style={{ "width": "100%", "height": "100%" }}>
                                                    <Image alt="banner-image" className={cssStyles.banner_desktop} priority src={bannerImage.src} layout="fill" onLoad={imageLoaded} />
                                                    <Image alt="banner-image-mobile" className={cssStyles.banner_mweb} priority src={bannerImageMweb.src} layout="fill" onLoad={imageLoaded} />
                                                </div> */}
                                                    <video ref={videoRef} id="banner-video" style={{ "width": "100%" }} muted loop autoPlay playsInline>
                                                        Your browser does not support the video tag.
                                                    </video>

                                                </div>)
                                                :
                                                (<div onClick={() => { setShowVideo(true); setCanAutoPlay(false) }}>
                                                    <Box component="div" className={cssStyles.content_section} sx={{display: 'flex'}}>
                                                        <Box component="div" className={cssStyles.content_left_section}>
                                                            <Box component="h3" className={cssStyles.caption} >
                                                                Don’t know where to begin? <br />
                                                                Need a personal interior design assistant?
                                                            </Box>
                                                            <Box component="h4" className={cssStyles.play_video_text}>
                                                                <img src={playButton.src} /> PLAY VIDEO TO LEARN MORE
                                                            </Box>
                                                        </Box>
                                                        <Box component="div" className={cssStyles.content_right_section}>
                                                            <img src={homeOwnerProblem.src} />
                                                        </Box>
                                                    </Box>
                                                </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Grid container spacing={2} className={cssStyles.discover_container}>
                            <Grid item xs={12} md={6} sx={{ padding: '0px !important' }}>
                                <Box component="div" className={cssStyles.content_section}>
                                    <Box component="h3" className="heading-3" sx={{ fontFamily: 'Lora' }}>Answer just a few questions to get <i>personalized</i> recommendations</Box>
                                    <Button id="discover_style_hero" color="primary" href="/stylequiz" sx={{ marginTop: '30px' }} onClick={() => {gtmClickTracking({event: "discover_style_hero", id: "discover_style_hero", value: "style_hero"})}}>Discover your style</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ padding: '0px !important' }}>
                                <img src={discoverStyleMweb.src} className={cssStyles["img-max-100"]} alt="Discover your style image" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: '0px', width: 'auto', margin: '0 !important' }} className={cssStyles.viewinroom_container}>
                            
                            <Grid item xs={12} md={6} sx={{ padding: '15px !important' }}>
                                <Box component="div" className={cssStyles.content_section}>
                                    <Box component="h3" className="heading-3" sx={{ fontFamily: 'Lora' }}>Ready to design? Choose a room to <i>get started</i>.</Box>
                                    <span style={{ "marginTop": '30px' }}>
                                        <Button id="start_with_the_room" sx={{ marginTop: '30px' }} color="primary" href="/roomselection"  onClick={() => {gtmClickTracking({event: "discover_room_hero", id: "discover_room_hero", value: "room_hero"})}}>Start with a room</Button>
                                    </span>
                                    {/* <span className={cssStyles.video_caption_mweb} style={{ "marginTop": '30px' }}>
                                        <RoomSelectorLayerButton id="start_with_the_room" color="primary" openedFrom="start_with_room" roomTypes={roomTypes}>Start with a room</RoomSelectorLayerButton>
                                    </span> */}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ padding: '15px !important', margin: '0px', textAlign: 'center' }}>
                                <img src="assets/img/perfect_fit.png" alt="" style={{maxWidth: '100%'}} className={`${cssStyles["img-rounded"]}`} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: '10px', marginBottom: '10px' }} className={cssStyles.viewinroom_container}>
                            <Grid item xs={12} md={6} sx={{ padding: '15px !important' }}>
                                <Box component="div" className={cssStyles.content_section}>
                                    <Box component="h3" className="heading-3" sx={{ fontFamily: 'Lora' }}>Need some <i>inspiration?</i> Let us show you.</Box>
                                    <Button id="browse_room_designs" color="primary" href="/inspirations" sx={{ marginTop: '30px' }} onClick={() => {gtmClickTracking({event: "browse_inspiration_hero", id: "browse_inspiration_hero", value: "inspiration_hero"})}}>browse inspirations</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ padding: '15px !important' }}>
                                <img src={viewInRoomMweb.src} style={{width: '100%'}} alt="Discover your style image" />
                            </Grid>
                        </Grid>
                    </Carousel>
                </section>

                <div className={"container-fluid"} style={{ "padding": "0" }}>
                    <Box component="div" className={"landing__heading_bg"} sx={{ bgcolor: 'backgrounds.bg_1' }}>
                        <Box component="div" className={"box-title"} sx={{ color: 'primary.light' }}>How Nestingale Works</Box>
                    </Box>
                </div>
                <section style={{ "padding": "0" }}>
                    <div className="container-spacing">

                        <Grid container spacing={2} className={cssStyles.discover_container}>
                            <Grid item xs={12} md={6} sx={{ padding: '0px !important' }}>
                                <Box component="div" className={cssStyles.content_section}>
                                    <Box component="h3" className="heading-3" sx={{ fontFamily: 'Lora' }}>Discover your style in just 2 minutes</Box>
                                    <Box component="div" sx={{ marginTop: '30px' }}>Take our quick style quiz to learn your style and then we’ll tailor recommendations to your personal taste.</Box>
                                    <Button id="discover_style_how_it_works" color="secondary" href="/stylequiz" sx={{ marginTop: '30px' }} onClick={() => {gtmClickTracking({event: "discover_style_how_it_works", id: "discover_style_how_it_works", value: "style_how_it_works"})}}>Discover your style</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ padding: '0px !important' }}>
                                <img src={discoverStyle.src} className={cssStyles["img-max-100"]} alt="Discover your style image" />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ marginTop: '0px', bgcolor: 'backgrounds.bg_3' }} className={cssStyles.design_perfectfit_container}>
                            <Grid item xs={12} md={6} sx={{ padding: '15px !important', margin: '0px' }}>
                                <img src="assets/img/perfect_fit.png" alt="" className={`${cssStyles["img-max-100"]} ${cssStyles["img-rounded"]}`} />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ padding: '0px !important' }}>
                                <Box component="div" className={cssStyles.content_section}>
                                    <Box component="h3" className="heading-3" sx={{ fontFamily: 'Lora' }}>Design and ensure the perfect fit</Box>
                                    <Box component="div" sx={{ marginTop: '30px', marginBottom: '30px' }}>{"Scan your rooms to create accurate, virtual room plans with our easy-to-use, immersive mobile app. Or, start with our 2D builder and we'll create a 3D room plan for you. And then design away in our easy builder tool!."}</Box>
                                    <span >
                                        <Button id="discover_room_how_it_works" color="secondary"  href="/roomselection" onClick={() => {gtmClickTracking({event: "discover_room_how_it_works", id: "discover_room_how_it_works", value: "room_how_it_works"})}}>Start with a room</Button>
                                    </span>
                                    {/* <span className={cssStyles.video_caption_mweb}>
                                        <RoomSelectorLayerButton id="start_with_the_room" color="secondary" openedFrom="start_with_room" roomTypes={roomTypes}>Start with a room</RoomSelectorLayerButton>
                                    </span> */}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: '10px', marginBottom: '10px' }} className={cssStyles.viewinroom_container}>
                            <Grid item xs={12} md={6}>
                                <Box component="div" className={cssStyles.content_section}>
                                    <Box component="h3" className="heading-3" sx={{ fontFamily: 'Lora' }}>View room designs for inspiration</Box>
                                    <Box component="div" sx={{ marginTop: '30px' }}>{"Need design ideas and inspiration? We're here to help. Get personalized recommendations, and shop different looks by room and style. Or, browse by category if you already have something in mind."}</Box>
                                    <Button id="browse_inspiration_how_it_works" color="secondary" href="/rooms" sx={{ marginTop: '30px' }} onClick={() => {gtmClickTracking({event: "browse_inspiration_how_it_works", id: "browse_inspiration_how_it_works", value: "inspiration_how_it_works"})}}>Browse Room Designs</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img src={viewInRoom.src} className={cssStyles["img-max-100"]} alt="Discover your style image" />
                            </Grid>
                        </Grid>

                    </div>
                </section>



                <Box component="div" className="container-fluid" sx={{ bgcolor: 'backgrounds.bg_2', padding: '0px !important' }}>
                    <div className="container-spacing">
                        <div className="testimonials">
                            <div className="message">
                                <div>“The visual designer is a game changer!”</div>
                                <span>PEDRO, MIAMI, FL</span>
                            </div>
                            <div className="message">
                                <div>“We loved how intuitive the visual designer was! We were able to design our room in just a few
                                    minutes!.”</div>
                                <span>JR & RACHEL, MIAMI, FL</span>
                            </div>
                            <div className="message">
                                <div>“The style quiz was such fun! The style vibe matches my taste!”</div>
                                <span>SAKETH, LOS ANGELES, CA</span>
                            </div>
                        </div>
                    </div>
                </Box>
                <div className="container-spacing">
                    <div className="row-gutter-2"></div>
                </div>
                <Box component="div" className={`${cssStyles["personal_assistance"]} container-spacing`} sx={{ bgcolor: 'backgrounds.bg_3' }}>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <Box component="h3" className="heading-3" sx={{ fontFamily: 'Lora' }}>Personal assistance every step of the way</Box>
                            <div className="row-gutter-2"></div>
                            <p>Personal assistance every step of the way Our customer support team is standing by to help bring your dream home to life. </p>
                            <p>Have questions? Email us at <a href="mailto:help@nestingale.com" id="mailto_help">Email us</a> or see our <a id="faq" href="#faq">FAQs</a>.</p>
                            <p>Prefer to work with a professional designer? Find one <a href="javascript:void(0)" id="find_prof_designer" onClick={handleSearchZipCodeLayerOpen}>here</a>.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={designerImage.src} className={cssStyles["img-rounded"]} style={{ margin: '15px 0', padding: '0px' }} alt="" />
                        </div>
                    </div>
                </Box>

                <div className="container-spacing">
                    <div className="row-gutter-2"></div>
                </div>


                <Box component="div" className="container-fluid" sx={{ bgcolor: 'backgrounds.bg_2', padding: "0" }}>
                    <div className="container-spacing">
                        <div className={cssStyles["flex-two-column"]}>
                            <div className={`${cssStyles["left-section"]} text-center`}>
                                <Box component="h3" className="heading-3" sx={{ fontFamily: 'Lora' }}>Want to discover your design style?</Box>
                                <div className="row-gutter-2"></div>
                                <Button id="take_style_quiz" color="secondary" className={"home-btn"} href="/stylequiz" sx={{ textTransform: 'uppercase', width: 'auto !important' }} onClick={() => {gtmClickTracking({event: "take_style_quiz", id: "take_style_quiz", value: "style_faq"})}}>take style quiz</Button>
                            </div>
                            <div className={`${cssStyles["right-section"]} text-center`}>
                                <Box component="h3" className="heading-3" sx={{ fontFamily: 'Lora' }}>Ready to start designing?</Box>
                                <div className="row-gutter-2"></div>
                                <span >
                                    <Button id="discover_room_faq" color="secondary" href="/roomselection" onClick={() => {gtmClickTracking({event: "discover_room_faq", id: "discover_room_faq", value: "room_faq"})}}>Start with a room</Button>
                                </span>
                                {/* <span className={cssStyles.video_caption_mweb}>
                                    <RoomSelectorLayerButton id="start_with_the_room" color="secondary" openedFrom="start_with_room" roomTypes={roomTypes}>Start with a room</RoomSelectorLayerButton>
                                </span> */}
                                {/*<AppleDownloadPopupLayerButton color="secondary">
                                        <span>Get your digital floorplan</span>
                                </AppleDownloadPopupLayerButton>*/}
                            </div>
                        </div>
                    </div>
                </Box>

                <span id="faq"></span>
                <div className="row-gutter-2"></div>
                <section>
                    <div className="container-spacing">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="heading-3 text-center">FAQs</h3>
                                <div className="row-gutter-2"></div>
                            </div>
                        </div>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ boxShadow: 'none', '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important',
                                    margin: '0 !important'
                                }}
                            ><strong>How is Nestingale different from other design services?</strong></AccordionSummary>
                            <AccordionDetails>Our goal is to empower homeowners to bring their dream home to life, and we
                                provide a one stop shop for homeowners throughout their design journey, from helping you discover your
                                style, to providing design inspiration, we help you reimagine your homes through personalized and
                                curated design recommendations, cutting-edge tools to let you immersively view recommendations in your
                                own spaces, and by helping you find trusted products and service providers to confidently bring your
                                vision to life. While doing all this, we care about delivering an exceptional experience and high
                                satisfaction, without you having to spend a lot of time or money.</AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
                            sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel2' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important',
                                    '.Mui-expanded': {
                                        margin: '10px 0 !important'
                                    }
                                }}
                            ><strong>How much does Nestingale cost?</strong></AccordionSummary>
                            <AccordionDetails>The Nestingale platform, including our app and all of our tools to help you
                                visualize and design your spaces, is 100% free for homeowners.You only pay for products and services
                                that you decide to purchase.</AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important'
                                }}
                            ><strong>What devices are compatible with your service?</strong></AccordionSummary>
                            <AccordionDetails>Currently, our services are available through a wide variety of devices, which
                                include desktop computers, laptops, and mobile devices. We recommend using a Chrome browser for the best
                                experience. We also have a mobile app to scan your room, which currently requires a LiDAR (Light
                                Detection and Ranging)  scanner based iPhone Pro or iPad Pro device. (It will work with any Apple Pro device with LiDAR camera technology)</AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important'
                                }}
                            ><strong>Can I talk to an interior designer for advice?</strong></AccordionSummary>
                            <AccordionDetails>Yes, we are onboarding interior designers onto our platform so that they are
                                available for consultations and services on demand, at a very reasonable price.This service is currently
                                available only in select zip codes, but we will continue to add new locations over time.</AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel5' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important'
                                }}
                            ><strong>Where do you source your furniture and accessories?</strong></AccordionSummary>
                            <AccordionDetails>We have partnered directly with top-of-the-line manufacturers and suppliers to
                                cut out the middleman and provide our customers with direct access to the highest quality products, at
                                the most competitive prices available.</AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel6' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important'
                                }}
                            ><strong>How does Nestingale make money?</strong></AccordionSummary>
                            <AccordionDetails>Our goal is to help bring your dream home to life and we do not charge
                                homeowners for our service. If you decide to purchase products and services on the platform, we earn a
                                small fee from the manufacturers and suppliers.</AccordionDetails>
                        </Accordion>
                    </div>
                </section>
                <div className="row-gutter-2"></div>
                <div className="container-spacing social-follow">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <span className="follow_us"><img src={heartIcon.src} alt="follow us icon" />Follow us</span>
                            <ul>
                                <li><a href="https://www.instagram.com/nestingale/" id="instagram_icon" target="_blank"><img
                                    src={instagramIcon.src} className="insta" alt="instagram icon" /></a></li>
                                <li><a href="https://www.facebook.com/nestingale" id="facebook_icon" target="_blank"><img
                                    src={facebookIcon.src} alt="facebook icon" /></a></li>
                                <li><a href="https://pin.it/5e8AwkD" id="pinterest_icon" target="_blank"><img
                                    src={pinterestIcon.src} alt="pinterest icon" /></a></li>
                                <li><a href="https://www.tiktok.com/@nestingale.com" id="tiktock_icon"><img
                                    src={tiktokIcon.src} alt="tiktock icon" /></a></li>
                                <li><a href="https://www.youtube.com/@nestingale" id="youtube_icon"><img
                                    src={youtubeIcon.src} alt="youtube icon" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row-gutter-2"></div>
            </main>
            <div className="modal fade bd-example-modal-lg" id="zip-search" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Search Zip</h4>
                            <button id="toggle_zip_search" type="button" className="close" onClick={toogleZipSearch}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex justify-content-center" style={{ "columnGap": "20px" }}>
                                <div><input type="text" name="zip" id="zip" /></div>
                                <div><a id="search_zip" href="javascript:void(0)" className="btn_new" onClick={searchZip}>Search</a></div>
                            </div>
                            <div id="zip-error" style={{ "display": "none" }}>
                                <div className="row-gutter-2"></div>
                                <div className="text-danger">We are not operative in this location. Will update soon.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Layer
                open={openSearchZipCodeLayer}
                onClose={handleSearchZipCodeLayerClose}
                dialogTitle=""
                Element={SearchZipCode}
                dialogContentClassName={"handle-design-your-roomLayer"}
                maxWidth="sm"
                dialogContentComponentProps="" />


        </>
    )
}

const SearchZipCode = () => {
    const [showZipError, setShowZipError] = useState(false);
    const searchZip = () => {
        setShowZipError(true);
    }

    return (
        <>
            <div className="message" style={{ padding: '0px 12px' }}>
                <Box component={'div'} p={0}>
                    <div style={{ 'marginBottom': '20px' }}><strong>Search Zip</strong></div>
                    {showZipError && <Alert severity="error" sx={{ marginBottom: '20px', }}>We are not operative in this location. Will update soon</Alert>}
                    <div className="d-flex justify-content-center" style={{ "columnGap": "20px" }}>
                        <TextField fullWidth id="fullWidth" />
                        <Button id="search_zip_continue" color="primary" size="small" onClick={(event: any) => searchZip()}>Continue</Button>
                    </div>
                </Box>
            </div>
        </>
    )
}

export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext) => {

    var { env, pageCacheInSec } = await getStaticBasics(context);
    const headerMenuJson = await getHeaderDataWithCache(env, pageCacheInSec);
    const roomTypes = await getRoomTypeList({ env: 'prod' });
    const {pageInserts} = await getPageInsertsWithCache(env, "landing", pageCacheInSec);

    const pagePropObj = {
        env: env,
        roomTypes: roomTypes,
        headerMenuJson: headerMenuJson,
        pageInserts
    };
    const pageProps = JSON.stringify(pagePropObj);

    return {
        props: {
            buildTime: Date().toString(),
            message: pageProps,
            title: `Nestingale - Design Inspiration, High Quality Furniture`,
            description: `Nestingale is the new way to imagine your dream home and bring it to life.  Find beautifully crafted and designed furniture, decorating ideas and design inspiration`,
            dataLayer: {
                pageType: "Welcome Page",
                pageName: "Welcome",
            }
        },
        // Next.js will attempt to re-generate the page: When a request comes in - At most once every 1 hour
        revalidate: pageCacheInSec, // 1 hour nn seconds
    }
}

export default Welcome;